import React, { useState, useEffect } from "react"
import Layout from '../containers/layout'
import FlyingStar from '../containers/FlyingStar'
import styled from 'styled-components'
import PeriodPicker from '../components/Picker/Period'
import DirectionPicker from '../components/Picker/Direction'
import { calculatePersonalGuaByYear } from '../cm-lib/XuanKongFlyingStar'
import { Box, Button, DateInput, Layer, TextArea, TextInput, List, Select, RadioButtonGroup } from 'grommet'
import { TWENTY_FOUR_MOUNTAIN } from '../cm-lib/constants'
import { create } from "react-test-renderer"
import { setStorage, getStorage } from '../helpers/index'
import { CHARTLIST_KEY } from '../constants' 

const Home = () => {

  const [lifegua, setLifeGua] = useState([])



  const [yearSelectionList, setYearSelectionList] = useState([...Array(121).keys()].map((e, i) => 1920 + i).reverse())
  const [selectedYear, setSelectedYear] = useState(2011)
  const [gender, setGender] = useState('M')


  useEffect(() => {
    const lifegua = calculatePersonalGuaByYear(selectedYear, gender)
    setLifeGua(lifegua)

  }, [gender, selectedYear])

  return <Layout>
      <Box gap="small">
    <Select
      options={yearSelectionList}
      value={selectedYear}
      onChange={({ option }) => setSelectedYear(option)}
    />
    <RadioButtonGroup
      name="doc"
      options={['M', 'F']}
      value={gender}
      onChange={(event) => setGender(event.target.value)}
    />
    <Button label={`${lifegua}`} />
    </Box>
  </Layout>
}

export default Home
